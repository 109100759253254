import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="select-client"
export default class extends Controller {
  static values = {
    program: String
  }

  static targets = ["selectedClient"]
  
  async change(event) {
    console.log(event.target.selectedOptions[0].value);

    // `/providers/clients/${event.target.selectedOptions[0].value}/emails`
    // `/providers/emails?user_id=${event.target.selectedOptions[0].value}`

    // get( `/providers/clients/${event.target.selectedOptions[0].value}/emails?program=${this.programValue}`, {
    //   responseKind: "turbo-stream"
    // })

    const response = await get( `/p/clients/${event.target.selectedOptions[0].value}/emails?program=${this.programValue}`, { responseKind: "turbo-stream" } )
    if (response.ok) {
      // 
      console.log("Superb email change triggered")
      this.selectedClientTarget.classList.remove("hidden");
    }
    
  }
}
