import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="scroll-to-bottom"
export default class extends Controller {
  connect() {
    console.log("hello scroll to bottom")
    this.scrollToBottom()
  }

  scrollToBottom() {
    const { scrollHeight, clientHeight, offsetHeight } = this.element
    if (scrollHeight >= clientHeight) {
      this.element.scrollTop = scrollHeight - clientHeight
    }
  }
}
