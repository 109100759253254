import { Controller } from "@hotwired/stimulus";
import intlTelInput from "intl-tel-input";

// Connects to data-controller="intl-tel-input"
export default class extends Controller {
  static targets = ["input"];

  connect() {
    const input = this.inputTarget;
    intlTelInput(input, {
      utilsScript:
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/18.1.5/js/utils.min.js",
      hiddenInput: "phone_number",
      initialCountry: "us",
      preferredCountries: ["us"],
      onlyCountries: ["us"],
      allowDropdown: false,
      separateDialCode: true,
      customContainer: "w-full text-sm jamal",
      // dropdownContainer: document.querySelector('#mdjamal'),
      autoPlaceholder: "aggressive",
    });
  }
}
