import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="selected-client"
export default class extends Controller {
  static values = {
    id: String,
  };

  connect() {
    this.toggle()
  }

  idValueChanged() {
    this.toggle()
  }

  update(event) {
    // Update selected id
    this.idValue = event.currentTarget
      .closest(".client")
      .id.replace("client_", "");
    console.log(this.idValue);
  }

  toggle() {
    // Remove active class for all .client instance
    const clientList = document.querySelectorAll(".client");
    // check if clientList is empty then return
    if (clientList.length === 0) {
      return;
    }
    for (let i = 0; i < clientList.length; i++) {
      clientList[i].classList.remove("client-active");
    }
    // Set active class for selected id
    const element = document.getElementById("client_" + this.idValue);
    console.log(element)
    element.classList.add("client-active");
  }

  trigger() {
    const parent = document.getElementById("client_" + this.idValue);
    // Trigger click event
    const child = parent.querySelector(`[id^="user_"]`)
    console.log(child)
    child.click()
  }
}
