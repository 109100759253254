import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="search-filter"
export default class extends Controller {
  filter(event) {
    // 
    event.preventDefault();
    // Select tag to update and trigger change
    var select = this.data.get("select-tag")
    var select = document.getElementById(select)
    // Clicked item
    const el = event.target
    const el_id = el.dataset.id
    // grab the option from select
    const option = document.getElementById(el_id)

    // Multiple Select
    // 1 toggle clicked select option true/false if present
    if (option) {
      option.selected = !option.selected
    }
    el.classList.toggle("active")
    // 2 Trigger Change event
    select.dispatchEvent(new Event('change'))
  }
}
