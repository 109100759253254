// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"

import LocalTime from "local-time"
LocalTime.start()

import "ahoy.js"
import "trix";

// local js files
import "./stripe"

document.addEventListener("turbo:load", () => {    
	// Begin Mobile Small devices menubar
	var toggle = document.getElementById("nav-toggler");
	var topNav = document.getElementById("navigation");

	// Listen for click event on toggle var
	if (toggle) {
		toggle.addEventListener(
			"click",
			function () {
				// console.log("toggle clicked");
				// toggle class "hidden" on topNav var
				topNav.classList.toggle("hidden");
			},
			false
		);
	}
	// End Mobile Small devices menubar
})